<template>
    <div>
        <div class="nav-menu" v-if="showMenu">
            <div class="cont-search">
                <div class="search-l">
                    <input placeholder="keywords" v-model="searchText" type="text">
                </div>
                <img src="@/assets/phone2/sousuo.png" alt="" @click="searchGame()">
                <!-- <div class="search-r">
                </div> -->
            </div>
            <div :class="{
                'menu-item':true,
                'sel-color':selectMenu == item.key_name,
                'nsel-color':selectMenu!=item.key_name
            }" v-for="item in navList" :key="item.category" @click="clickMenu(item.key_name)">
                <div>{{item.key_name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { getClassList } from "@/api/games"
    export default {
        data() {
            return {
                navList:[],
                selectMenu:'Hot',
                showMenu:false,
                searchText: ''
            }
        },
        mounted() {
            this.getclasslist();
            eventBus.$on('showmenu',i=>{                
                this.showMenu = i;
            })
        },
        methods: {
            async getclasslist(){
                this.navList = await getClassList();
            },
            clickMenu(item){
                this.selectMenu = item;
                if(this.$route.path=='/'){
                    eventBus.$emit('selectMenu',item);
                }else{
                    this.$router.push({
                        name: 'index',
                        params:{
                            menuKey:item
                        }
                    })
                }
            },
            searchGame(){
                this.showMenu = false;
                eventBus.$emit('showfenlei',false)
                if(this.$route.path != '/selectGame'){
                    this.$router.push({
                        path: '/selectGame',
                        query: {
                            id: 'search',
                            key: this.searchText
                        }
                    })
                }else{
                    eventBus.$emit('searchgame',this.searchText);
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .nav-menu{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .nav-menu{
    width: 100%;
  }
}
.nav-menu{
    &::-webkit-scrollbar{
        display: none;
    }
    height: 315px;
    // background: #FFFFFF;
    background: #FFF;
    border-radius: 1px;
    overflow: auto;
    position: fixed;
    z-index: 999;
    top: 50px;
    // left: 0;
    .cont-search{
        display: flex;
        // padding: 0 20px;
        margin: 10px auto 11px;
        width: 338px;
        height: 40px;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        background: #373952;
        line-height: 40px;
        border-radius: 10px;
        .search-l{
            width: 281px;
            height: 40px; 
            // background: #FFFAF9;
            border-radius: 10px;
            overflow: hidden;
            input{
                &::placeholder {
                    color: #FFF;
                }
                background: #373952;
                margin-left: 13px;
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
            }
        }
        img{
            margin-right: 12px;
            width: 22px;
            height: 22px;
        }
    }
    .sel-color{
        color: #373952;
    }
    .nsel-color{
        color: #888;
    }
    .menu-item{
        margin: 0 auto;
        width: 315px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        line-height: 39px;
        text-align: center;
        font-style: normal;
        border-bottom: 1px solid #E7E7E7;
    }
}
</style>